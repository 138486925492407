import { AppBar, Toolbar, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import NavigationButton from './NavigationButton';
import logo from './../assets/logoForHeader.svg'

const useStyles = makeStyles(() => ({
  appBar: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    flexGrow: 1,
    color: 'gray',
  },
  logo: {
    height: 30
  }
}));

function Header() {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.header}>
          <img src={logo} alt="logo" style={{height: 54, verticalAlign: "middle", padding: 8}} />
        </Typography>
        <NavigationButton />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
