import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import TableSortLabel from '@mui/material/TableSortLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { InputAdornment } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import calculateTaxables from './calculateTaxables';
import { FaRegCopy } from 'react-icons/fa'
import CopyToClipBoard from 'react-copy-to-clipboard';

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: '#f5f6fa',
  },
  inputField: {
    width: 100,
  },
  inputText: {
    textAlign: 'right',
  },
  copy: {
    "&:active": {
      filter: "invert(0.5)"
    }
  }
}));

// TODO: unit.jsへ
const convertToKanji = (v) => {
  return v === 'buy' ? '買' : '売';
};

// TODO: unit.jsへ
const addUnitYen = (v) => {
  return Number(v).toLocaleString() + ' 円';
};

// TODO: unit.jsへ
const addUnitCrypto = (v, chain) => {
  return chain === 'eth' || chain === 'rinkeby'
    ? Number(v).toLocaleString() + ' ETH'
    : Number(v).toLocaleString() + ' MATIC';
};

// TODO: unit.jsへ
const unitToChainName = (unit) => {
  if (unit === 'eth') return 'Ethereum';
  else if (unit === 'rinkeby') return 'Rinkeby';
  else if (unit === 'polygon') return 'Polygon';
  else if (unit === 'mumbai') return 'Mumbai';
};

const chainToUnitName = (chain) => {
  if (chain === 'eth' || chain === 'rinkeby') return 'ETH';
  else if (chain === 'polygon' || chain === 'mumbai') return 'MATIC';
};

const BasicTable = (props) => {
  const classes = useStyles();
  
  // sortのためのstate
  const [state, setState] = useState({
    rows: [],
    order: 'desc',  
    key: null, 
  });
  
  // ウォレットアドレスを入力して取引を検索するたびにテーブルを初期化する
  React.useEffect(() => {
    setState({
    rows: props.data.result, 
    order: 'desc',  
    key: null, 
  })}, [props.data.result]);
  
  const handleClickSortColumn = (column) => {
    const isDesc = column === state.key && state.order === 'desc';
    const nextOrder = isDesc ? "asc" : "desc";
    const sortRule = { asc: [1, -1], desc: [-1, 1] };
    const sortedRows = state.rows
      .sort((a, b) => {
      if (a[column] > b[column]) {
        return sortRule[nextOrder][0];
      } else if (a[column] < b[column]) {
        return sortRule[nextOrder][1];
      } else {
        return 0;
      }
    });
    
    setState({
      rows: sortedRows, 
      order: nextOrder,
      key: column
    });
  };

  function handleNftCostInputChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    props.setNftCosts({ ...props.nftCosts, [name]: value });
  }

  function handleCryptoCostInputChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    props.setCryptoCosts({ ...props.cryptoCosts, [name]: value });
  }
  
  const calculateCryptoCost = (row, cryptoCosts, useBulkInputPrice, cryptoPurchasePrice) => {
    if (useBulkInputPrice) {
      return addUnitYen(calculateTaxables(row.value_jpy, Number(cryptoPurchasePrice[chainToUnitName(row.chain)])))
    } else {
      return typeof cryptoCosts[row.token_key] === 'undefined' ? addUnitYen(calculateTaxables(row.value_jpy, row.crypto_cost)) : addUnitYen(calculateTaxables(row.value_jpy, Number(cryptoCosts[row.token_key])))
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="transaction table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell align="center" style={{ minWidth: 30 }}> No.  </TableCell>
            <TableCell align="center" style={{ minWidth: 76 }}>
              <TableSortLabel 
                active
                direction={state.order}
                onClick={() => handleClickSortColumn('token_key')}
              >
                トークンアドレス
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" style={{ minWidth: 76 }}> トークンID </TableCell>
            <TableCell align="center" style={{ minWidth: 76 }}> チェーン </TableCell>
            <TableCell align="center" style={{ minWidth: 136 }}> 
              <TableSortLabel 
                active
                direction={state.order}
                onClick={() => handleClickSortColumn('datetime')}
              >
                取引日時
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" style={{ minWidth: 50 }}> 売 / 買 </TableCell>
            <TableCell align="center" style={{ minWidth: 120 }}> 値段 </TableCell>
            <TableCell align="center" style={{ minWidth: 100 }}> 値段（取引時） </TableCell>
            <Tooltip disableFocusListener disableTouchListener title="NFTの購入価格を入力してください" placement="top">
              <TableCell align="center" style={{ minWidth: 100 }}>
                NFT原価
              </TableCell>
            </Tooltip>
            <Tooltip disableFocusListener disableTouchListener title="NFTの購入に利用した暗号通貨の取得価格を入力してください" placement="top">
              <TableCell align="center" style={{ minWidth: 100 }}>
                暗号通貨原価
              </TableCell>
            </Tooltip>
            <TableCell align="center" style={{ minWidth: 100 }}> 売却益 </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { state.rows
            .filter((row) => props.chainTypesCheck[unitToChainName(row.chain)])  // チェックがついてるチェーンだけ残す
            .map((row, index) => (
              <TableRow
                key={row.transaction_hash}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" style={{ minWidth: 30 }}> {index + 1} </TableCell>
                <TableCell align="center" style={{ minWidth: 76 }}> 
                  {row.token_address.slice(0, 14)}...
                  <CopyToClipBoard text={row.token_address}><FaRegCopy className={classes.copy}/></CopyToClipBoard>
                </TableCell>
                <TableCell align="center" style={{ minWidth: 76 }}> 
                  {row.token_id.length > 6 ? row.token_id.slice(0, 5)+"..." : row.token_id+" "}
                  <CopyToClipBoard text={row.token_id}><FaRegCopy className={classes.copy}/></CopyToClipBoard>
                </TableCell>
                <TableCell align="center" style={{ minWidth: 76 }}> {unitToChainName(row.chain)} </TableCell>
                <TableCell align="center" style={{ minWidth: 136 }}> {row.datetime} </TableCell>
                <TableCell align="center" style={{ minWidth: 50 }}> {convertToKanji(row.buy_or_sell)} </TableCell>
                <TableCell align="right" style={{ minWidth: 120 }}> {addUnitCrypto(row.value_crypto, row.chain)} </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}> {addUnitYen(row.value_jpy)} </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}>
                  {row.nft_cost === -1 ? (
                    '- ' // -1 ではなくて buy_or_sell で判定した方がベターなのでそのうち直す
                  ) : (
                    <TextField
                      id="nft-cost"
                      variant="standard"
                      name={row.token_key}
                      defaultValue={row.nft_cost}
                      className={classes.inputField}
                      inputProps={{ style: { textAlign: 'right' } }}
                      InputProps={{
                        style: { textAlign: 'right' },
                        endAdornment: <InputAdornment position="end">円</InputAdornment>,
                      }} // makeStyles にまとめた方がいい気がする
                      onChange={handleNftCostInputChange}
                    />
                  )}
                </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}>
                  {row.crypto_cost === -1 ? (
                    '- ' // -1 ではなくて buy_or_sell で判定した方がベターなのでそのうち直す
                  ) : (
                    <TextField
                      id="crypto-cost"
                      variant="standard"
                      name={row.token_key}
                      defaultValue={row.crypto_cost}
                      className={classes.inputField}
                      inputProps={{ style: { textAlign: 'right' } }} // makeStyles にまとめた方がいい気がする
                      InputProps={{
                        style: { textAlign: 'right' },
                        endAdornment: <InputAdornment position="end">円</InputAdornment>,
                      }}
                      onChange={handleCryptoCostInputChange}
                      disabled={props.useBulkInputPrice}
                    />
                  )}
                </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}>
                  {row.buy_or_sell === 'buy'
                    ? calculateCryptoCost(row, props.cryptoCosts, props.useBulkInputPrice, props.cryptoPurchasePrice)
                    : typeof props.nftCosts[row.token_key] === 'undefined' ? addUnitYen(calculateTaxables(row.value_jpy, row.nft_cost)) : addUnitYen(calculateTaxables(row.value_jpy, Number(props.nftCosts[row.token_key]),))}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BasicTable;