import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { Grid, FormControlLabel, FormGroup } from '@material-ui/core';
import { Stack, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';

let renderChainChangeHandler = (chainName, chainTypesCheck, setChainTypesCheck) => {
  return (event) => {
    let nextState = Object.assign({}, chainTypesCheck);
    nextState[chainName] = event.target.checked;
    setChainTypesCheck(nextState);
  };
};

const renderChainCheckBox = (chainTypesCheck, setChainTypesCheck) => {
  let checkboxes = Object.keys(chainTypesCheck).map((chainName) => {
    return (
      <FormControlLabel
        label={chainName}
        key={chainName}
        control={
          <Checkbox
            checked={chainTypesCheck[chainName]}
            onChange={renderChainChangeHandler(chainName, chainTypesCheck, setChainTypesCheck)}
          />
        }
      />
    );
  });
  return checkboxes;
};

const renderTaxClassChangeHandler = () => {
  // TODO: 書く
};

const renderTaxClassCheckBox = () => {
  return (
    <FormControlLabel
      label="NFT売買を本業の一部として行っている"
      key="a"
      control={
        <Checkbox
        // TODO: チェックが付いたときの処理をちゃんと書く
        // checked={chainTypesCheck[chainName]}
        // onChange={}
        />
      }
    />
  );
};

const renderCostBulkInput = (
  cryptoPurchasePrice,
  setCryptoPurchasePrice,
  useBulkInputPrice,
) => {
  const handleCryptoPurchasePrice = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setCryptoPurchasePrice({ ...cryptoPurchasePrice, [name]: value })
  }

  return (
    <Grid container spacing={2} noValidate>
      <Grid item style={{maxWidth: 200}}>
        <TextField
          //label='avg. price'
          name="ETH"
          variant="standard"
          disabled={!useBulkInputPrice}
          // TODO: 単位の ETHだけ小さくする
          // TODO: 入力を処理につなげる
          size='small'
          inputProps={{ style: { textAlign: 'right' } }}
          InputProps={{
            style: { textAlign: 'right' },
            endAdornment: <InputAdornment position="end">円/ETH</InputAdornment>
          }}
          onChange={handleCryptoPurchasePrice}
        />
      </Grid>

      <Grid item style={{maxWidth: 200}}>
        <TextField 
          //label='avg. price'
          name="MATIC"
          variant="standard"
          disabled={!useBulkInputPrice}
          // TODO: 単位の ETHだけ小さくする
          // TODO: 入力を処理につなげる
          size='small'
          inputProps={{ style: { textAlign: 'right' } }}
          InputProps={{
            style: { textAlign: 'right' },
            endAdornment: <InputAdornment position="end">円/MATIC</InputAdornment>
          }}
          onChange={handleCryptoPurchasePrice}
        />
      </Grid>
    </Grid>
  );
};

const CheckBoxes = (props) => {
  const chainCheckBoxes = renderChainCheckBox(props.chainTypesCheck, props.setChainTypesCheck);
  //const taxClassCheckBox = renderTaxClassCheckBox();
  const costBulkInput = renderCostBulkInput(
    props.cryptoPurchasePrice,
    props.setCryptoPurchasePrice,
    props.useBulkInputPrice,
  );

  const handleBulkInputChange = (e) => {
    props.setUseBulkInputPrice(e.target.value==="bulkInput")
  }

  return (
    <>
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        marginBottom: 1,
        marginLeft: 1,
        fontWeight: 'bold'
      }}
    >
      税額計算設定
    </Box>

    <Box
      component={Paper}
      noValidate
      autoComplete="off"
      sx={{
        backgroundColor: 'white',
        marginBottom: 0,
        borderRadius: 1,
        width: 548,
        padding: 2
      }}
    >
      <Stack>
        <Grid container spacing={2} noValidate>
          <Grid item style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Box sx={{alignText:"center"}}>
              チェーン種類: 
            </Box>
          </Grid>

          <Grid item style={{ display: 'flex' }}>
            <FormGroup row>{chainCheckBoxes}</FormGroup>
          </Grid>
        </Grid>
        {/*
        <Grid container spacing={2} noValidate>
          <Grid item style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            課税区分
          </Grid>

          <Grid item style={{ display: 'flex' }}>
            {taxClassCheckBox}
          </Grid>
        </Grid>
        */}
        <Grid container spacing={2} noValidate>
          <Grid item style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            暗号通貨原価: 
          </Grid>
          <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
            <RadioGroup row aria-label="gender" name="row-radio-buttons-group" defaultValue="eachInput" onChange={handleBulkInputChange}>
              <FormControlLabel value="eachInput" control={<Radio />} label="個別入力" />
              <FormControlLabel value="bulkInput" control={<Radio />} label="一括入力" />
            </RadioGroup>
          </FormControl>
          <Grid item style={{ display: 'flex' }}>
            {costBulkInput}
          </Grid>
        </Grid>
      </Stack>
    </Box>
    </>
  );
};

export default CheckBoxes;
