/**
 * 課税対象金額を計算（小数点第1位まで出力）
 */

const calculateTaxables = (profit, cost) => {
  const taxables = Number((profit - cost).toFixed(1))
  return taxables > 0 ? taxables : 0
}

export default calculateTaxables;
