import React from 'react';
import { makeStyles } from '@material-ui/core';
import logo from '../assets/logo.svg';

const useStyles = makeStyles({
  footer: {
    color: '#ffffff',
    backgroundColor: '#334B72',
    bottom: 0,
    textAlign: 'right',
    padding: 14,
  },
  text: {
    color: '#ffffff',
    padding: 20,
  },
});

const Footer = () => {
  const classes = useStyles();
  return <div className={classes.footer}>
    <div className="footer-detail-middle--inner flex-row">
      <div className="footer-detail-menu-text">
        <a className={classes.text} href="/policy" target="_blank" rel="noreferrer noopener">
          プライバシーポリシー
        </a>
        <a className={classes.text} href="/policy" target="_blank" rel="noreferrer noopener">
          利用規約
        </a>
      </div>
    </div>
  </div>;
};
export default Footer;
