import './App.css';
import Contact from './components/Contact';
import Main from './components/Main';
import Header from './components/Header';
import Footer from './components/Footer';
import Box from '@mui/material/Box';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

export const path = {
  home: '/',
  contact: '/contact',
};

function App() {
  return (
      <Router>
        <Header />
          <Box sx={{m: 3}}> 
            <Routes>
              <Route exact path={path.home} element={<Main />} />
              <Route exact path={path.contact} element={<Contact />} />
            </Routes>
          </Box>
        {/*<Footer />*/}
      </Router>
  );
}

export default App;
