import React, { useState } from 'react';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

const contactURL = process.env.REACT_APP_CONTACT_URL

const notifySuccess = () => {
  toast.success('送信が完了しました', {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

const notifyFailure = () => {
  toast.error('送信に失敗しました', {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

const notifyInvalidContent = () => {
  toast.error('入力内容を確認してください', {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

const isEnterKey = (event) => {
  return event.keyCode === 13;
}

class FormContent {
  constructor(name, example, key, [state, setState]) {
    this.name = name;
    this.example = example;
    this.key = key;  // GASのAPIを叩く際のObjectのkey
    this.state = state; 
    this.setState = setState;
  }

  generateErrorMessage = (ignoreFocus) => {
    if (!this.state.isFocusedOnce) return '';
    if (this.state.value === '') return this.name + 'を入力してください';
    if (this.name === 'メールアドレス' && !validator.isEmail(this.state.value))  {
      return this.name + 'の形式が正しくありません';
    }
    return '';
  };

  focused = () => {
    this.setState({...this.state, isFocusedOnce: true});
  };

  updateValue = (value) => {
    this.setState({...this.state, value: value});
  };

  isError = () => {
    return this.generateErrorMessage() !== '';
  }

  isEmpty = () => {
    return this.state.value === '';
  }
}

function Contact() {
  const [sending, setSending] = useState();

  const formObject = {
    value: '',
    isFocusedOnce: false,
  };

  const formContents = [
    new FormContent('メールアドレス', 'info@example.com', 'mail_address', useState(formObject)),
    new FormContent('ウォレットアドレス', '0x...', 'wallet_address', useState(formObject)),
    new FormContent('件名', '機能の要望, バグの報告, ご感想, ...', 'title', useState(formObject)),
    new FormContent('お問い合わせ内容', '', 'content', useState(formObject)),
  ];

  const renderTextFields = () => {
    return (
      <>
      {formContents.map(content => {
        if (content.name === 'お問い合わせ内容') { // お問い合わせ内容だけ複数行
          return (
            <TextField
              id={content.name}
              label={content.name}
              placeholder={content.example}
              fullWidth
              required
              multiline
              rows={12}
              onBlur={() => content.focused()}
              error={content.isError()}
              helperText={content.generateErrorMessage()}
              onChange={(event) => content.updateValue(event.target.value)}
            />
          )
        } else {
          return (
            <TextField
              id={content.name}
              label={content.name}
              placeholder={content.example}
              fullWidth
              required
              onBlur={() => content.focused()}
              error={content.isError()}
              helperText={content.generateErrorMessage()}
              onChange={(event) => content.updateValue(event.target.value)}
            />
          )
        }
      }
      )}
      </>
    );
  };
  
  const someContentIsInvalid = () => {
    formContents.forEach(content => content.focused());
    return (
      formContents.map(content => content.generateErrorMessage(true))
        .some(message => message !== '') ||
      formContents.map(content => content.state.value)
        .some(value => value === '')
    );
  };

  const submitContactForm = (e) => {
    e.preventDefault();
    
    // フォーム入力に誤りがあったら送信しない
    if (someContentIsInvalid()) {
      notifyInvalidContent();
      return;
    }

    setSending(true);

    const query = Object.fromEntries(
      new Map(
        formContents.map(content => [content.key, content.state.value])
      )
    );
    
    axios
      .post(contactURL, query, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then((res) => {
        setSending(false);
        //console.log(res.data);
        notifySuccess();
      })
      .catch((err) => {
        //TODO: もっとちゃんとしたerror handlingをする
        setSending(false);
        if (err.message === 'Network Error') {
          notifySuccess();
        } else {
          //console.log(err);
          notifyFailure();
        }
      });
  };

  return (
    <Box
      component="form"
      sx={{
        backgroundColor: 'white',
        borderRadius: 1.5
      }}
      noValidate
      autoComplete="off"
    >
      <Stack
        component="form"
        sx={{
          margin: '2em 4em',
        }}
        spacing={2}
        noValidate
        autoComplete="off"
      >
        {/*  FIXME: 空白のために空divを置いてる  */}
        <div />
        <div />

        {renderTextFields()}
        
        <ToastContainer />

        <LoadingButton 
          onClick={(event) => submitContactForm(event)} 
          variant="contained" 
          loading={sending}
          endIcon={<SendIcon />}
          onKeyDown={(event) => {
            if (isEnterKey(event)) {
              submitContactForm(event)};
            }
          }
        >
          送信する
        </LoadingButton>

        {/*  FIXME: 空白のために空divを置いてる  */}
        <div />
        <div />
      </Stack>
    </Box>
  );
}

export default Contact;
