import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Grid } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import Form from './Form';
import Table from './Table';
import CashInfo from './CashInfo';
import CheckBoxes from './CheckBoxes';
import axios from 'axios';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableEmpty: {
    backgroundColor: '#f5f6fa',
    padding: 200,
    textAlign: "center",
  }
}));

const notify = () => {
  toast.error('データが取得できませんでした', {
    position: toast.POSITION.BOTTOM_LEFT,
  });
};

function Main() {
  const [chainTypesCheck, setChainTypesCheck] = useState({
    Ethereum: true,
    Polygon: true,
    Rinkeby: false,
    Mumbai: false,
  });

  const [useBulkInputPrice, setUseBulkInputPrice] = useState(false);
  const [cryptoPurchasePrice, setCryptoPurchasePrice] = useState({"ETH": 0, "MATIC": 0});

  const [loading, setLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');

  const [nftCosts, setNftCosts] = useState({});
  const [cryptoCosts, setCryptoCosts] = useState({});

  const [apiResult, setApiResult] = useState({ total: -1, result: [] });

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    setApiResult({ total: -1, result: [] })
    setNftCosts({})
    setCryptoCosts({})
    const postObject = {
      address: walletAddress,
      chains: ['polygon', 'eth', 'rinkeby'],
    };
    axios
      .post(process.env.REACT_APP_API_HOST, postObject, {
        withCredentials: true,
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        //console.log(res.data);
        setApiResult(res.data);
        setLoading(false);
      })
      .catch(() => {
        notify();
        setLoading(false);
      });
  };

  // TODO: unit.jsへ
  const unitToChainName = (unit) => {
    if (unit === 'eth') return 'Ethereum';
    else if (unit === 'rinkeby') return 'Rinkeby';
    else if (unit === 'polygon') return 'Polygon';
    else if (unit === 'mumbai') return 'Mumbai';
  };

  const displayTableEmpty = (apiResult) => {
    switch (apiResult.total) {
      case -1:
        return <Box className={classes.tableEmpty}>ウォレットアドレスを入力して取引履歴を検索してみよう</Box>
      case 0:
        return <Box className={classes.tableEmpty}>2021/01/01 - 2021/12/31 の取引データは見つかりませんでした</Box>
      default:
        return
    }
  }

  const classes = useStyles();

  return (
    <>
      <ToastContainer />
      <Grid container direction="row">
        <Grid item sm={9} xs={12}>
          <Form onSubmit={onSubmit} loading={loading} walletAddress={walletAddress} setWalletAddress={setWalletAddress} />
          <CheckBoxes
            chainTypesCheck={chainTypesCheck}
            setChainTypesCheck={setChainTypesCheck}
            cryptoPurchasePrice={cryptoPurchasePrice}
            setCryptoPurchasePrice={setCryptoPurchasePrice}
            useBulkInputPrice={useBulkInputPrice}
            setUseBulkInputPrice={setUseBulkInputPrice}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <CashInfo
            data={apiResult.result.filter((e) => chainTypesCheck[unitToChainName(e.chain)])}
            nftCosts={nftCosts}
            cryptoCosts={cryptoCosts}
            useBulkInputPrice={useBulkInputPrice}
            cryptoPurchasePrice={cryptoPurchasePrice}
          />
        </Grid>
      </Grid>
      <div style={{ color: '#4D4F5C', textAlign: 'right' }}> 検索結果: {apiResult.result.filter((e) => chainTypesCheck[unitToChainName(e.chain)]).length} 件 </div>
      <Table
        data={apiResult}
        chainTypesCheck={chainTypesCheck}
        nftCosts={nftCosts} setNftCosts={setNftCosts}
        cryptoCosts={cryptoCosts}
        setCryptoCosts={setCryptoCosts}
        useBulkInputPrice={useBulkInputPrice}
        cryptoPurchasePrice={cryptoPurchasePrice}
      /> 
      {displayTableEmpty(apiResult)}
    </>
  );
}

export default Main;
