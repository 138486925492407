import * as React from 'react';
// import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

function NavigationButton() {
  const currentPath = useLocation().pathname;
  return (
    <>
    <a color="primary" target="_blank" rel="noreferrer" href="https://note.com/k_viledge/n/nec6190b5b82a" style={{"fontSize": 13, "paddingRight": 30, "textDecoration": "none"}} >利用方法 / 利用時の注意</a>
    <Button style={ {"fontSize": 13}} color="primary" component={Link} to={currentPath === '/contact' ? '/' : '/contact'}>
      {currentPath === '/contact' ? '　　戻る　　' : 'お問い合わせ'}
    </Button>
    </>
  );
}

export default NavigationButton;
