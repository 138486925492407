import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@material-ui/core';
import StorageIcon from '@mui/icons-material/Storage';

const useStyles = makeStyles(() => ({
  textFieldForm: {
    '& .MuiInputBase-input': {
      backgroundColor: 'white',
      borderRadius: 6, // TODO: 正確な値を設定する
    },
  },
}));

const isEnterKey = (event) => {
  return event.keyCode === 13;
}

function FormPropsTextFields(props) {
  const classes = useStyles();
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        marginBottom: 2,
      }}
    >
      <Grid container spacing={2} noValidate>
        <Grid item>
          <TextField
            required
            label="wallet address"
            sx={{ width: '50ch' }}
            size='small'
            className={classes.textFieldForm}
            defaultValue={props.walletAddress}  // FIXME: 便宜上木村さんのやつをデフォルトにしてます
            onChange={(event) => props.setWalletAddress(event.target.value)}
            onKeyDown={(event) => {
              if (isEnterKey(event)) {
                props.onSubmit(event)};
              }
            }
          />
        </Grid>
        
        {/* TODO: 3ボタンをひとかたまりにする */}

        <Grid item style={{ display: 'flex' }}>
          <LoadingButton
            onClick={(event) => props.onSubmit(event)}
            variant="contained"
            loading={props.loading}
            style={{maxWidth: '120px', maxHeight: '40px', minWidth: '120px', minHeight: '40px'}}
            // endIcon={<StorageIcon />} // NOTE: 別になくても良いかも
          >
            取引を集計
          </LoadingButton>
        </Grid>
        
        <Grid item style={{ display: 'flex' }}>
          <LoadingButton
            onClick={(event) => props.onSubmit(event)}
            variant="contained"
            //*loading={props.loading}  // 実装する時に復活させる
            style={{maxWidth: '120px', maxHeight: '40px', minWidth: '120px', minHeight: '40px'}}
            disabled={true}
          >
            CSVで出力
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FormPropsTextFields;
