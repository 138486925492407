import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import calculateTaxables from './calculateTaxables';

const chainToUnitName = (chain) => {
  if (chain === 'eth' || chain === 'rinkeby') return 'ETH';
  else if (chain === 'polygon' || chain === 'mumbai') return 'MATIC';
};

const CashInfo = (props) => {
  const sumNftSaleProfit = (transactions, nftCosts) => {
    if (typeof transactions === 'undefined'){ return 0 }
    let sumNftSaleProfit = 0
    transactions.filter(e => e.buy_or_sell === "sell").forEach(e => {
      typeof nftCosts[e.token_key] === 'undefined' ? sumNftSaleProfit += calculateTaxables(e.value_jpy, e.nft_cost) : sumNftSaleProfit += calculateTaxables(e.value_jpy, nftCosts[e.token_key])
    });
    return sumNftSaleProfit.toLocaleString();
  }
  
  const sumCryptoSaleProfit = (transactions, cryptoCosts, useBulkInputPrice, cryptoPurchasePrice) => {
    if (typeof transactions === 'undefined'){ return 0 }
    let sumCryptoSaleProfit = 0
    if (useBulkInputPrice) {
      transactions.filter(e => e.buy_or_sell === "buy").forEach(e => {
        sumCryptoSaleProfit += calculateTaxables(e.value_jpy, e.value_crypto * cryptoPurchasePrice[chainToUnitName(e.chain)])
      })
    } else {
      transactions.filter(e => e.buy_or_sell === "buy").forEach(e => {
        typeof cryptoCosts[e.token_key] === 'undefined' ? sumCryptoSaleProfit += calculateTaxables(e.value_jpy, e.crypto_cost) : sumCryptoSaleProfit += calculateTaxables(e.value_jpy, cryptoCosts[e.token_key])
      })
    }
    return sumCryptoSaleProfit;
  }

  return (
    <Box sx={{ 
      backgroundColor: 'white',
      borderRadius: 1, // TODO: 正確な値を設定する
      padding: 2
    }}
      component={Paper}
    >

        {/* TODO: いい感じに: の左側を揃えたい */}
        <div style={{textAlign: 'right'}}>
          <span>NFT売却益:  </span>
          <span style={{fontWeight: "bold"}}>{sumNftSaleProfit(props.data, props.nftCosts).toLocaleString()} 円</span>
        </div>
        <div style={{textAlign: 'right'}}>
          <span>暗号通貨売却益:  </span>
          <span style={{fontWeight: "bold"}}>{sumCryptoSaleProfit(props.data, props.cryptoCosts, props.useBulkInputPrice, props.cryptoPurchasePrice).toLocaleString()} 円</span>
        </div>
    </Box>
  )
}

export default CashInfo;